function navigationHeight() {
    var navHeight = 0;
    $("nav .nav-wrapper .nav-content").each(function() {
        navHeight += $(this).outerHeight();
    });
}

$(".hamburger").click(function() {
    $(".hamburger").toggleClass("active");
    $("body").toggleClass("backdrop");

    if ($("body").hasClass("backdrop")) {
        $("nav .nav-wrapper").slideDown("fast", function() {
            TweenMax.staggerTo(
                ".nav-content ul:first li",
                0.6,
                {
                    x: 0,
                    opacity: 1,
                    delay: 0.1,
                    ease: Power3.easeOut,
                    force3D: true,
                },
                0.05
            );
        });
    } else {
        $("nav .nav-wrapper").slideUp("fast", function() {
            TweenMax.to(".nav-content ul:first li", 0, {
                x: -5,
                opacity: 0,
            });
        });
    }
});
