$(function() {
    if (!$(".hamburger").is(":visible")) {
        // on desktop set about-alcohol element fixed when scrolling past it.
        if ($("section").length > 0) {
            var controller = new ScrollMagic.Controller();
            var scene = new ScrollMagic.Scene({
                triggerElement: "section",
                triggerHook: "onEnter",
            })
                .setPin(".about-alcohol", { pushFollowers: false })
                .addTo(controller);
        }
    }
});
