$(function() {
    function setNavWrapperHeight() {
        var h = $(".about-alcohol").outerHeight();
        $("nav .nav-wrapper").css("height", "calc(100% - " + h + "px)");
    }

    setNavWrapperHeight();

    function setContentHeight() {
        var h = $(".code-form .step2").outerHeight() + 15;
        $("main .content").css("min-height", h);
    }

    setContentHeight();

    $(window).on("resize", function() {
        setNavWrapperHeight();
        setContentHeight();
    });
});
