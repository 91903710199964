$(function() {
    function doScrollToTarget(target, animate) {
        var elTop = 0;

        if ($(target).length == 1) {
            // hamburger visible?
            if ($(".hamburger").is(":visible")) {
                if ($("body").hasClass("backdrop")) {
                    // navigation is expanded, so close it
                    $(".hamburger").click();
                }
                elTop = $(target).offset().top - 46;
            } else {
                elTop = $(target).offset().top;
            }

            if (animate) {
                $("html, body").animate(
                    {
                        scrollTop: elTop,
                    },
                    300
                );
            } else {
                $(window).scrollTop(parseInt(elTop));
            }
        }
    }

    $(".scrollTo").on("click", function() {
        var $this = $(this),
            target = $this.data("target");

        doScrollToTarget(target, true);
    });

    var hash = window.location.hash;
    if (hash) {
        if ($(hash).length == 1) {
            doScrollToTarget(hash, false);
        }
    }
});
