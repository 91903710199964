var $errorWrapper = $("#code-form .error-message");

function showError(message, $button) {
    $errorWrapper.html(message).addClass("show");

    // IE Edge pseudo element bug fix
    // Trigger DOM element change so pseudo element can render border properly
    // Yes, I know, are you kidding me?

    setTimeout(function() {
        $(".code-form label").css("cursor", "pointer");
    }, 10);

    $button
        .removeClass("checking-code")
        .find("span")
        .show();
    $button.find(".checking").hide();
}

function convertFormToJSON(form) {
    var array = $(form).serializeArray();
    var ignores = ["permission", "agecheck"];
    var json = {};

    $.each(array, function() {
        if (ignores.indexOf(this.name) == -1) {
            json[this.name] = this.value || "";
        }
    });

    return json;
}

//remove error class when checking radio or checkbox
$("input[type=radio], input[type=checkbox]").on("change", function() {
    var $this = $(this);
    if ($this.val() !== "") {
        $this.removeClass("field-error");

        if ($this.hasClass("form-checkbox")) {
            $this
                .parents(".form-group")
                .find(".form-checkbox")
                .removeClass("field-error");
        }
    }
});

$("input[type=text]").on("keyup", function() {
    var $this = $(this);

    if ($this.attr("name") == "name" && $this.val() !== "") {
        $this.removeClass("field-error");
    }
    if (
        $this.attr("name") == "email" &&
        $this.val() !== "" &&
        /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/.test($this.val())
    ) {
        $this.removeClass("field-error");
    }
});

$("#code-form").on("submit", function(e) {
    e.preventDefault();

    var $form = $(this);
    var valid = 0;
    var internalError = $form.data("error");
    var invalidCodeError = $form.data("errorinvalid");
    var incompleteMessage = $form.data("errorincomplete");
    var codeAlreadyUsedError = $form.data("erroralreadyused");
    var $submitButton, jqRequest;

    // reset
    $form.find("input").removeClass("field-error");

    // first step, code check
    if ($(".visible").hasClass("step1")) {
        var codeToFormat = $(".your-code").val();
        $(".your-code").val(codeToFormat.toUpperCase());

        var code = $(".your-code").val();
        var pattern = /^[0-9A-Z]{8}$/i;
        $submitButton = $form.find("button[type=submit]#check-code");

        if (pattern.test(code) && code !== "") {
            // valid code
            $(".your-code").attr("disable", true);
            $submitButton.addClass("checking-code");
            $submitButton.find("span").hide();
            $submitButton.find(".checking").show();

            // reset any previous errors
            $errorWrapper.removeClass("show").html("&nbsp;");

            var formData = {
                code: code,
                phone: "",
            };

            // check entered code
            jqRequest = {
                url: metaData.restBase + "/lottery/code",
                type: "POST",
                data: JSON.stringify(formData),
                contentType: "application/json",
            };
            $.ajax(jqRequest)
                .done(function(response) {
                    if (response.message) {
                        switch (response.message) {
                            case "NOTFOUND":
                            case "INVALID":
                                showError(invalidCodeError, $submitButton);
                                break;

                            case "USED":
                                showError(codeAlreadyUsedError, $submitButton);
                                break;

                            case "ERROR":
                                showError(internalError, $submitButton);
                                break;

                            case "VALID":
                                setTimeout(function() {
                                    $(".step1").removeClass("visible");
                                    $(".step2").addClass("visible");
                                }, 800);
                                break;

                            default:
                                showError(internalError, $submitButton);
                                break;
                        }
                    } else {
                        showError(internalError, $submitButton);
                    }
                })
                .fail(function(jqXHR, textStatus, errorThrown) {
                    showError(internalError, $submitButton);
                });
        } else {
            // invalid code, show error
            showError(invalidCodeError, $submitButton);
        }
    }

    // second step, personal details
    if ($(".visible").hasClass("step2")) {
        $submitButton = $form.find("button.submit-form");

        // validate form fields
        // gender
        var gender = $form.find("input[name='gender']");
        if (gender.is(":checked")) {
            valid++;
        } else {
            gender.addClass("field-error");
        }

        // fullname
        var fullname = $form.find("input[name='name']");
        if (fullname.val() !== "") {
            valid++;
        } else {
            fullname.addClass("field-error");
        }

        // email
        var email = $form.find("input[name='email']");
        if (
            email.val() !== "" &&
            /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/.test(email.val())
        ) {
            valid++;
        } else {
            email.addClass("field-error");
        }

        // agecheck
        var agecheck = $form.find("input[name='agecheck']");
        if (agecheck.is(":checked")) {
            valid++;
        } else {
            agecheck.addClass("field-error");
        }

        // all valid, do submit
        if (valid == 4) {
            $submitButton
                .addClass("checking-code")
                .find("span")
                .hide();
            $submitButton.find(".checking").show();

            jqRequest = {
                url: metaData.restBase + "/lottery/draw",
                type: "POST",
                data: JSON.stringify(convertFormToJSON($form)),
                contentType: "application/json",
            };
            $.ajax(jqRequest)
                .done(function(response) {
                    if (response.message) {
                        switch (response.message) {
                            case "NOTFOUND":
                            case "INVALID":
                                showError(invalidCodeError, $submitButton);
                                break;

                            case "USED":
                                showError(codeAlreadyUsedError, $submitButton);
                                break;

                            case "ERROR":
                                showError(internalError, $submitButton);
                                break;

                            case "LOST":
                                window.location.href =
                                    $form.data("redirectloser") +
                                    "?code=" +
                                    response.id;
                                break;

                            case "WON":
                                window.location.href =
                                    $form.data("redirectwinner") +
                                    "?code=" +
                                    response.id;
                                break;

                            default:
                                showError(internalError, $submitButton);
                                break;
                        }
                    } else {
                        showError(internalError, $submitButton);
                    }
                })
                .fail(function(jqXHR, textStatus, errorThrown) {
                    $submitButton
                        .removeClass("checking-code")
                        .find("span")
                        .show();
                    $submitButton.find(".checking").hide();

                    showError(internalError, $submitButton);
                });
        } else {
            showError(incompleteMessage, $submitButton);
        }
    }
});
